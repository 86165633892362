<template>
  <div>
    <span>{{ base }}</span>
    <span class="text-danger font-weight-bold">{{ extraDigit }}</span>
    <span> g</span>
  </div>
</template>

<script>
import { roundThree } from '@/helpers';

export default {
  name: 'ThreeDecimalGrammage',
  components: {},
  props: ['grammage'],
  data() {
    return {
      base: null,
      extraDigit: null,
    };
  },
  methods: {
    init() {
      /* eslint prefer-destructuring: ["error", {VariableDeclarator: {object: true}}] */
      const rounded = roundThree(this.grammage);
      const decimalCount = rounded % 1 === 0 ? 0 : rounded.toString().split(',')[1].length;
      this.base = rounded;
      this.extraDigit = null;
      if (decimalCount >= 3) {
        this.base = this.base.slice(0, -1);
        this.extraDigit = rounded[rounded.length - 1];
      }
    },
  },
  watch: {
    grammage: {
      immediate: true,
      handler() {
        this.init();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
